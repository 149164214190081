import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import { Route, Switch } from "react-router-dom";

// import ContactUs from './pages/Contact.js'
import Product from "../src/pages/Products/product.js";
import suspend from "../src/pages/suspended/suspend.js";
import Footer from "./Components/Footer/Footer.js";
import Navbar from "./Components/Navbar/Navbar.js";
import Home from "./pages/Home/Home.js";
import Roofing from "./pages/Roofing/roofing.js";
import Grilles from "./pages/grilles/grilles.js";
import Halfbricks from "./pages/halfbricks/halfbricks.js";
import Ventilators from "./pages/ventilators/ventilators.js";
import Walling from "./pages/walling/walling.js";

function App() {
  const [locale, setLocale] = useState("enUS");

  return (
    <div className="App" style={{ overflow: "hidden" }}>
      <Navbar />
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/ventilators" component={Ventilators} exact />
        <Route path="/grilles" component={Grilles} exact />
        <Route path="/roofing" component={Roofing} exact />
        <Route path="/half" component={Halfbricks} exact />
        <Route path="/wall" component={Walling} exact />
        <Route path="/suspend" component={suspend} exact />
        <Route path="/products" component={Product} exact />
      </Switch>
      <Footer />
    </div>
  );
}

export default App;
